import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';

import {Observable} from 'rxjs';


@Injectable()
export class GetService {
  constructor(private http: HttpClient) {}

  // postData(url: string, data: any): Observable<any> {
  //   return this.http.post<HttpResponse<any>>(url, data);
  // }

  postData(url: string, data: any): Observable<any> {
    return this.http.post<HttpResponse<any>>(url, data, { headers: {'Content-Type': 'application/json'}
    } );
  }

  getData(url: string): Observable<any> {
    return this.http.get<HttpResponse<any>>(url);
  }
}
