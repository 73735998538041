import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    localStorage.setItem('line', '0');
    localStorage.setItem('point', '0');
    localStorage.setItem('heart', '3');
    localStorage.setItem('answerreview', '<table class="ui celled table"><thead class=""><tr class=""><th class="">Sual</th><th class="">Cavabınız</th>' +
      '</tr></thead><tbody class="">');
    localStorage.setItem('questpoint', '0');
    localStorage.setItem('answeredlist', '"answeredlist": [');
  }

}
