import { Component, OnInit } from '@angular/core';
import {GetService} from '../get.service';
import {environment} from '../../environments/environment';
import { CookieService } from 'angular2-cookie/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private http1: GetService, private _cookieService: CookieService) { }

  todos: { id: number , name: string , icon: string , color: string , status: number }[] ;

  Open_Question(id: number) {
     this._cookieService.put('id', id.toString());
  }

  ngOnInit() {
    this.http1.getData(environment.BASE_URL + 'getcat').subscribe(data => this.todos = data );
  }
}
