import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlayComponent } from './play/play.component';
import { HomeComponent } from './home/home.component';
import { StartComponent } from './start/start.component';
import { QuestionComponent } from './question/question.component';
import {HttpClientModule} from '@angular/common/http';
import {GetService} from './get.service';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { EndComponent } from './end/end.component';


@NgModule({
  declarations: [
    AppComponent,
    PlayComponent,
    HomeComponent,
    StartComponent,
    QuestionComponent,
    EndComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule

  ],
  providers: [GetService, CookieService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
