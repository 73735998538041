import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {GetService} from '../get.service';
import { CookieService } from 'angular2-cookie/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  line: number;
  point: number;
  heart: number;
  color: string;
  questpoint: number;
  end = false;
  answeredlist: string;

  answerreview: string;

  constructor( private http1: GetService, private _cookieService: CookieService , private router: Router) { }

  questions: { question_id: number , category_id: number , question: string , score: number , questionDetails: {id: number, question_id: number, choises: string, correct: number }[]}[];


  ngOnInit() {

    this.answerreview = localStorage.getItem('answerreview');
    this.line = Number(localStorage.getItem('line'));
    this.point = Number(localStorage.getItem('point'));
    this.heart = Number(localStorage.getItem('heart'));
    this.answeredlist = localStorage.getItem('answeredlist');

    this.http1.getData(environment.BASE_URL + 'getquestionbycat/' + this._cookieService.get('id') ).subscribe(data => {
      this.questions = data;
      this.questpoint = Number(data[this.line].score);
    });
    localStorage.setItem('questpoint', this.questpoint.toString());
  }

  checkanswerA() {
    this.answeredlist += '{ "question_id": ' + this.questions[this.line].question_id + ', "category_id": ' + this.questions[this.line].category_id
      + ', "answer_id":' + this.questions[this.line].questionDetails[0].id + '}';

    this.answerreview += '<tr class=""><td class="">' + this.questions[this.line].question + '</td><td class="">';
    if ( this.end === false && this.questions[this.line].questionDetails[0].correct === 1) {
      this.point += this.questions[this.line].score
      this.answerreview += '<i aria-hidden="true" class="green checkmark icon"></i> ' + this.questions[this.line].questionDetails[0].choises + '</td></tr>';
    } else {
      this.answerreview += '<i aria-hidden="true" class="red close icon"></i> ' + this.questions[this.line].questionDetails[0].choises + '</td></tr>';
       this.heart = this.heart - 1;
    }

         this.line += 1;
    localStorage.setItem('answerreview', this.answerreview);
    localStorage.setItem('line', this.line.toString());
    localStorage.setItem('point', this.point.toString());
    localStorage.setItem('heart', this.heart.toString());

    if ((this.questions.length <= this.line) || this.heart < 1) {
      localStorage.setItem('answeredlist', this.answeredlist);
      this.end = true;
      this.router.navigate(['end']);
    } else {
      this.answeredlist += ',';
      localStorage.setItem('answeredlist', this.answeredlist);
    }
  }

  checkanswerB() {

    this.answeredlist += '{ "question_id": ' + this.questions[this.line].question_id + ', "category_id": ' + this.questions[this.line].category_id
      + ', "answer_id":' + this.questions[this.line].questionDetails[1].id + '}';

    this.answerreview += '<tr class=""><td class="">' + this.questions[this.line].question + '</td><td class="">';
    if (this.end === false && this.questions[this.line].questionDetails[1].correct === 1) {
      this.point += this.questions[this.line].score;
      this.answerreview += '<i aria-hidden="true" class="green checkmark icon"></i> ' + this.questions[this.line].questionDetails[1].choises + '</td></tr>';
    } else {
      this.answerreview += '<i aria-hidden="true" class="red close icon"></i> ' + this.questions[this.line].questionDetails[1].choises + '</td></tr>';
      this.heart = this.heart - 1;
    }

    this.line += 1;

    localStorage.setItem('answerreview', this.answerreview);
    localStorage.setItem('line', this.line.toString());
    localStorage.setItem('point', this.point.toString());
    localStorage.setItem('heart', this.heart.toString());

    if ((this.questions.length <= this.line) || this.heart < 1) {
      localStorage.setItem('answeredlist', this.answeredlist);
      this.end = true;
      this.router.navigate(['end']);
    } else {
      this.answeredlist += ',';
      localStorage.setItem('answeredlist', this.answeredlist);
    }
  }

  checkanswerC() {

    this.answeredlist += '{ "question_id": ' + this.questions[this.line].question_id + ', "category_id": ' + this.questions[this.line].category_id
      + ', "answer_id":' + this.questions[this.line].questionDetails[2].id + '}';

    this.answerreview += '<tr class=""><td class="">' + this.questions[this.line].question + '</td><td class="">';
    if (this.end === false && this.questions[this.line].questionDetails[2].correct === 1) {
      this.point += this.questions[this.line].score;
      this.answerreview += '<i aria-hidden="true" class="green checkmark icon"></i> ' + this.questions[this.line].questionDetails[2].choises + '</td></tr>';
    } else {
      this.answerreview += '<i aria-hidden="true" class="red close icon"></i> ' + this.questions[this.line].questionDetails[2].choises + '</td></tr>';
      this.heart = this.heart - 1;
    }


    this.line += 1;

    localStorage.setItem('answerreview', this.answerreview);
    localStorage.setItem('line', this.line.toString());
    localStorage.setItem('point', this.point.toString());
    localStorage.setItem('heart', this.heart.toString());

    if ((this.questions.length <= this.line) || this.heart < 1) {
      localStorage.setItem('answeredlist', this.answeredlist);
      this.end = true;
      this.router.navigate(['end']);
    } else {
      this.answeredlist += ',';
      localStorage.setItem('answeredlist', this.answeredlist);
    }

  }

}
