import { Component, OnInit } from '@angular/core';
import {GetService} from '../get.service';
import {environment} from '../../environments/environment';
import { v4 as uuid } from 'uuid';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.css']
})
export class EndComponent implements OnInit {

  point: number;
  answerlist: string;
  answerreview: string;
  review = false;



  constructor(private http1: GetService) { }

  ngOnInit() {
    this.answerlist = localStorage.getItem('answeredlist');
    this.point = Number(localStorage.getItem('point'));
    this.answerlist = '{ "score": ' + this.point.toString() + ', "token": "' + uuid() +  '", "username": ""' + ',' + this.answerlist + ' ] }';
    localStorage.setItem('answeredlist', this.answerlist);

    this.http1.postData(environment.BASE_URL + 'insertanswer',  this.answerlist )
      .subscribe(data => {
    });

    this.answerreview = localStorage.getItem('answerreview');
    this.answerreview += '</tbody></table>';
  }

  reviewmethod() {
    this.review = !this.review;
  }

}
